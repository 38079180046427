export default function Footer() {
  const links = [
    { name: 'Impressum', href: '/impressum' },
    { name: 'Datenschutzerklärung', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
  ];

  return (
    <footer className="relative bottom-4 right-0 flex flex-col gap-4 md:flex-row justify-end pr-4">
      {links.map((link) => (
        <a
          key={link.name}
          href={link.href}
          className="text-sm text-gray-400 hover:text-[#6C8AE4] transition-colors"
        >
          {link.name}
        </a>
      ))}
    </footer>
  );
}