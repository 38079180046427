import { useEffect } from "react";

export default function Impressum() {

  useEffect(() => {
    // Setze den Titel auf "Impressum+"
    document.title = "Impressum";
  }, []);


  return (
    <div className="min-h-screen pt-20 pb-12 px-4">
      <div className="max-w-3xl mx-auto bg-[#3A3A3A] rounded-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Impressum</h1>

        <div className="space-y-6 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Nexiva ist ein Angebot der:</h2>
            <p>Fraab UG (haftungsbeschränkt)</p>
            <p>Kurt-Eisner Str. 17</p>
            <p>04275 Leipzig</p>
            <p>Deutschland</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Kontakt:</h2>
            <p>E-Mail-Adresse: <a className="text-blue-500 hover:underline" href="mailto:kontakt@nexiva.de">kontakt@nexiva.de</a></p>
            <p>Telefon: <a href="tel:+493412253850">0341 – 225 38 50</a></p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Meldung nach DSGVO:</h2>
            <p>Datenschutzbeauftragter: <a className="text-blue-500 hover:underline" href="mailto:dsb@nexiva.de">dsb@nexiva.de</a></p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Vertreten durch:</h2>
            <p>Geschäftsführer: Steffen Günthel</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Registereintrag:</h2>
            <p>Eingetragen im Handelsregister.</p>
            <p>Registergericht: Amtsgericht Leipzig</p>
            <p>Registernummer: HRB 31111</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Umsatzsteuer-ID:</h2>
            <p>Umsatzsteuer-Identifikationsnummer nach §27a UStG: DE298329384</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Online-Streitbeilegung (OS):</h2>
            <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a className="text-blue-500 hover:underline" href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">https://ec.europa.eu/consumers/odr/</a> finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Disclaimer – rechtliche Hinweise:</h2>
            <p className="text-l font-semibold text-white mb-2">§ 1. Haftungsausschluss:</p>
            <p>Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.</p> <br />

            <p className="text-l font-semibold text-white mb-2">§ 2. Links auf fremde Webseiten:</p>
            <p>Die Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und wir machen sie uns nicht zu Eigen. Für alle Inhalte und Nachteile, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, übernehmen wir keine Verantwortung.</p> <br />

            <p className="text-l font-semibold text-white mb-2">§ 3. Urheberrechte und Markenrechte:</p>
            <p>Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.</p> <br />

            <p className="text-l font-semibold text-white mb-2">§ 4. Erklärung zur Öffentlichmachung der Umsatzsteuer Identifikationsnummer:</p>
            <p>Kleinunternehmer i. S. v. § 19 Abs. 1 UStG haben ein Wahlrecht, ob sie Umsatzsteuer abführen wollen. Entscheiden sie sich gegen die Abführung, benötigen sie grundsätzlich keine Umsatzsteuer-Identifikationsnummer. Dementsprechend müssen sie auch im Impressum keine Umsatzsteuer-Identifikationsnummer angeben. Hinzu kommt, dass Kleinunternehmer häufig nicht ins Handelsregister eingetragen sind, sodass auch insoweit keine Angaben erforderlich sind. Besteht allerdings eine Registereintragung oder wurde eine Umsatzsteuer-Identifikationsnummer erteilt, sind die entsprechenden Angaben zu machen. Das Muster geht davon aus, dass keine Umsatzsteuer-Identifikationsnummer vorliegt und dass der Betreiber der Internetseite nicht ins Handelsregister eingetragen ist. Für das Muster wurde ferner von einem journalistisch-redaktionell gestalteten Angebot ausgegangen, so dass die Anbieterkennzeichnung nach § 55 Abs. 2 RStV gesondert aufgenommen wurde.</p>
          </section>
        </div>
      </div>
    </div>
  );
}
